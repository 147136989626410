.Footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    background-color: #46455b;
    color: #ffffff;
    padding: 25px;
    padding-top: 60px;
    box-sizing: border-box;
}

.Statut {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    font-size: .8rem;
    color: #9b9e9e;
    margin: 15px 0 0 15px;
    box-sizing: border-box;
}