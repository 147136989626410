.Flex, .FlexColumn, .FlexRow, .Header, .Apropos, .Services, .SolutionItem, .CarouselItem, .FormContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.FlexColumn, .Header, .SolutionItem, .CarouselItem, .FormContainer {
    width: 100%;
    flex-direction: column;
}

.FlexRow, .Apropos, .Services {
    width: 100%;
    flex-direction: row;
}

.Flex {
    flex-direction: row;
}

.Logo {
    margin: 20px 0;
    width: 100%;
    height: 90px;
    background-image: url('../../asset/img/logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.Header {
    justify-content: flex-start;
    height: 500px;
    width: 100%;
    padding-top: 25px;
    background-color: #46455b;
    color: #ffffff;
    background-image: url('../../asset/img/headImg.svg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
}

.Header .HeadTitle {
    text-align: center;
    font-size: 3.0rem;
    font-weight: 800;
    max-width: 90%;
}

.Header .HeadSubtitle {
    text-align: center;
    margin-top: 20px;
    font-size: 1.2rem;
    max-width: 70%;
}

.Title {
    width: 100%;
    margin: 50px 0;
    text-align: center;
    font-size: 2.8rem;
    font-weight: 800;
    color: #8c72cc;
}

.Apropos, .Services {
    flex-wrap: wrap;
    padding: 25px;
}

.Apropos .Left, .Apropos .Right {
    width: 50%;
    box-sizing: border-box;
}

.Apropos .Left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    background-color: #46455b;
    color: #ffffff;
    padding: 15px;
    text-align: justify;
    font-size: 1.2rem;
    clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 100%);
}

.Apropos .Right {
    height: 300px;
    background-image: url('../../asset/img/apropos.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.Services .Left, .Services .Right, .ConseilImg, .FormationImg, .SolutionItem {
    width: 50%;
    box-sizing: border-box;
}

.Services .Right {
    height: 400px;
    background-color: #8c72cc;
}

.Services .RightContainer {
    height: 400px;
    background-color: #ffffff;
    clip-path: polygon(0 8%, 100% 0, 100% 92%, 0 100%);
    background-image: url('../../asset/img/services.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.Services .ConseilImg {
    height: 300px;
    background-image: url('../../asset/img/conseil.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.Services .FormationImg {
    height: 200px;
    background-image: url('../../asset/img/formation.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.Services .SolutionItem {
    justify-content: flex-start;
    text-align: center;
    min-height: 280px;
    min-width: 280px;
    max-width: 280px;
    max-height: 280px;
    padding: 15px;
    background-color: #ffffff;
    color: #46455b;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 0 3px 3px #7b7291;
}

.CardImg {
    height: 80px;
    margin-bottom: 15px;
}

.CarouselItem {
    background-color: #46455b;
    padding: 10px 35px;
    height: 400px;
    color: #ffffff;
    text-align: justify;
    font-size: .9rem;
}

.FormContainer {
    width: 500px;
}

.FormGroup {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;
}

.SubmitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150px;
    background-color: #8c72cc;
    color: #ffffff;
    font-size: 1.0rem;
    font-weight: 600;
    border-radius: 25px;
    border-color: transparent;
    box-sizing: border-box;
    margin-bottom: 50px;
}

.SubmitBtn:hover, .SubmitBtn:focus, .SubmitBtn:active{
    border-color: transparent;
}

.PersonCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 300px;
    margin: 15px;
    background-color: #ffffff;
    box-shadow: 0 0 3px 3px #46455b;
    box-sizing: border-box;
}

.Nom {
    margin-top: 10px;
    color: #8c72cc;
    font-weight: 600;
}

.Profil {
    color: #46455b;
    font-size: .8rem;
    text-align: center;
    margin: 0 15px;
}

.PersonImg {
    width: 200px;
    height: 200px;
}

.Youtube {
    width: 560px;
    height: 315px;
}

@media(max-width: 700px){
    .Logo {
        height: 60px;
    }

    .Title {
        font-size: 2.2rem;
    }

    .Header .HeadTitle {
        font-size: 2.2rem;
    }

    .Header .HeadSubtitle {
        font-size: 1.0rem;
    }

    .Apropos .Left, .Apropos .Right {
        width: 100%;
    }

    .Apropos .Left {
        font-size: .9rem;
    }

    .Services .Left, .Services .Right, .ConseilImg, .FormationImg, .SolutionItem {
        width: 100%;
    }

    .Services .SolutionItem {
        min-height: 280px;
        min-width: 280px;
        max-width: 280px;
        max-height: 280px;
    }

    .FormContainer {
        width: 90%;
    }

    .Youtube {
        width: 250px;
        height: 140px;
    }
}